<template>
  <div class="max-w-sm w-full sm:mr-6 rounded bg-white overflow-hidden shadow-lg mt-6">
    <div class="px-6 py-4">
      <div class="text-gray-900 font-bold text-xl mb-2">{{event.name}}</div>
      <p class="text-gray-700 text-base">
        {{ event.internalNotes }}
      </p>
    </div>
    <div class="px-6 pt-4 pb-2">
      <router-link :to="'/admin/event/'+event.id">
      <button class="px-4 py-2 bg-gray-600 rounded-md text-white font-medium text-sm tracking-wide hover:bg-gray-500 0 text-center"> Bearbeiten </button>
    </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Event',
  props: {
    event: Object
  },
  data() {
    return {

    }
  }
};
</script>
