<template>
    <div class="event">
      <h1>Events</h1>
      <div class="flex flex-wrap">
        <Event :event="event" />
      </div>

    </div>
</template>

<script>
    import Event from "@/components/Admin/Events/Event.vue";

    export default {
        name: 'event',
      components: {
          Event
      },
        data() {
            return {
                event: {
                  id: "d11438fd-10b8-4d91-b9ca-2906e3c03da9",
                  accountId: 22492,
                  name: "Summer Workshop",
                  slug: "summer-workshop",
                  subscriberListId: 23,
                  startDate: "2022-07-24",
                  endDate: "2022-07-24",
                  settingsId: "e994a0fb-0a49-477f-8ebc-bd8ac9e023c7",
                  internalNotes: "Here are some internal eventnotes",
                  maxGuestCount: 3,
                  status: "ACTIVE",
                  createdAt: "2020-09-21 09:41:52",
                  updatedAt: "2020-09-22 10:09:12"
                }
            }
        }
    }
</script>

<style>


</style>
